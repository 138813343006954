class HeaderImage {
  file: string;
  name: string;
  category: string;
}
const headerImages: HeaderImage[] = [
  // {file: 'Appreciation_Confetti.jpg', category: 'Appreciation', name: 'Confetti'},
  {file: 'Patient Appreciation-100.jpg', category: 'Appreciation', name: 'Confetti'},
  {file: 'Celebrate_Fireworks.jpg', category: 'Celebrate', name: 'Fireworks'},
  {file: 'Couple_2-Couples.jpg', category: 'Couple', name: '2 Couples'},
  {file: 'Couple_Balancing-at-Beach.jpg', category: 'Couple', name: 'Balancing at Beach'},
  {file: 'Couple_Beach.jpg', category: 'Couple', name: 'Beach'},
  {file: 'Couple_Canoe.jpg', category: 'Couple', name: 'Canoe'},
  {file: 'Couple_Dancing.jpg', category: 'Couple', name: 'Dancing'},
  {file: 'Couple_Drinking-Coffee.jpg', category: 'Couple', name: 'Drinking Coffee'},
  {file: 'Couple_Driving-in-Car.jpg', category: 'Couple', name: 'Driving in Car'},
  {file: 'Couple_Gardening.jpg', category: 'Couple', name: 'Gardening'},
  {file: 'Couple_Kitchen.jpg', category: 'Couple', name: 'Kitchen'},
  {file: 'Couple_Meditation.jpg', category: 'Couple', name: 'Meditation'},
  {file: 'Couple_Picnic.jpg', category: 'Couple', name: 'Picnic'},
  {file: 'Couple_Sitting-on-Pier.jpg', category: 'Couple', name: 'Sitting on Pier'},
  {file: 'Couple_Sleeping.jpg', category: 'Couple', name: 'Sleeping'},
  {file: 'Couple_Sofa-with-Smartphone.jpg', category: 'Couple', name: 'Sofa with Smartphone'},
  {file: 'Couple_Watermelon-Picnic.jpg', category: 'Couple', name: 'Watermelon Picnic'},
  {file: 'Couple_Woman-with-Insomnia.jpg', category: 'Couple', name: 'Woman with Insomnia'},
  {file: 'Couple_Yoga-Outdoors.jpg', category: 'Couple', name: 'Yoga Outdoors'},
  {file: 'Doctor_Speaking-at-Event.jpg', category: 'Doctor', name: 'Speaking at Event'},
  {file: 'Exercise_Class.jpg', category: 'Exercise', name: 'Class'},
  {file: 'Exercise_Couple-Stretching.jpg', category: 'Exercise', name: 'Couple Stretching'},
  {file: 'Exercise_Cycling.jpg', category: 'Exercise', name: 'Cycling'},
  {file: 'Exercise_Jogging.jpg', category: 'Exercise', name: 'Jogging'},
  {file: 'Exercise_Outdoors.jpg', category: 'Exercise', name: 'Outdoors'},
  {file: 'Exercise_Tai-Chi.jpg', category: 'Exercise', name: 'Tai Chi'},
  {file: 'Exercise_Yoga.jpg', category: 'Exercise', name: 'Yoga'},
  {file: 'Family_Holding-Hands.jpg', category: 'Family', name: 'Holding Hands'},
  {file: 'Food_Apple-with-Heart.jpg', category: 'Food', name: 'Apple with Heart'},
  {file: 'Food_Apples.jpg', category: 'Food', name: 'Apples'},
  {file: 'Food_Chef-Cooking.jpg', category: 'Food', name: 'Chef Cooking'},
  {file: 'Food_Grapes.jpg', category: 'Food', name: 'Grapes'},
  {file: 'Food_Holiday-Dinner.jpg', category: 'Food', name: 'Holiday Dinner'},
  {file: 'Food_Salad.jpg', category: 'Food', name: 'Salad'},
  {file: 'Food_Sliced-Apples.jpg', category: 'Food', name: 'Sliced Apples'},
  {file: 'Holiday_Buffet.jpg', category: 'Holiday', name: 'Buffet'},
  {file: 'Holiday_Generic.jpg', category: 'Holiday', name: 'Generic'},
  {file: 'Holiday_Hanukkah.jpg', category: 'Holiday', name: 'Hanukkah'},
  {file: 'Holiday_Thanksgiving.jpg', category: 'Holiday', name: 'Thanksgiving'},
  {file: 'Holiday_Xmas.jpg', category: 'Holiday', name: 'Xmas'},
  {file: 'Man_Hammock.jpg', category: 'Man', name: 'Hammock'},
  {file: 'Man_Hiking.jpg', category: 'Man', name: 'Hiking'},
  {file: 'Man_Relaxing-on-Sofa.jpg', category: 'Man', name: 'Relaxing on Sofa'},
  {file: 'Man_Working-Out.jpg', category: 'Man', name: 'Working Out'},
  {file: 'Medical_Doctor-Patient.jpg', category: 'Medical', name: 'Doctor Patient'},
  {file: 'Men_Riding-Mountain-Bikes.jpg', category: 'Men', name: 'Riding Mountain Bikes'},
  {file: 'Misc_Artist.jpg', category: 'Misc', name: 'Artist'},
  {file: 'Misc_Charity.jpg', category: 'Misc', name: 'Charity'},
  {file: 'Misc_Get-Ahead-Of-Your-Health.jpg', category: 'Misc', name: 'Get Ahead Of Your Health'},
  {file: 'Misc_Grilling.jpg', category: 'Misc', name: 'Grilling'},
  {file: 'Misc_Group-of-People.jpg', category: 'Misc', name: 'Group of People'},
  {file: 'Misc_Gut-Health-Graphic.jpg', category: 'Misc', name: 'Gut Health Graphic'},
  {file: 'Misc_Hand-Heart.jpg', category: 'Misc', name: 'Hand Heart'},
  {file: 'Misc_Maze.jpg', category: 'Misc', name: 'Maze'},
  {file: 'Misc_People-Youthful-Spirit.jpg', category: 'Misc', name: 'People Youthful Spirit'},
  {file: 'Misc_Resolution_Goals.jpg', category: 'Misc', name: 'Resolution Goals'},
  {file: 'Misc_Vitamins-Supplements.jpg', category: 'Misc', name: 'Vitamins Supplements'},
  {file: 'Misc_Welcome.jpg', category: 'Misc', name: 'Welcome'},
  {file: 'Misc_Who-What-Why-Where-When-How.jpg', category: 'Misc', name: 'Who What Why Where When How'},
  {file: 'Season_Autumn-Leaves.jpg', category: 'Season', name: 'Autumn Leaves'},
  {file: 'Season_Autumn-Walk.jpg', category: 'Season', name: 'Autumn Walk'},
  {file: 'Season_Winter_Skiing.jpg', category: 'Season', name: 'Winter Skiing'},
  {file: 'Season_Winter_Sledding.jpg', category: 'Season', name: 'Winter Sledding'},
  {file: 'Season_Winter-Family.jpg', category: 'Season', name: 'Winter Family'},
  {file: 'Sport_Baseball.jpg', category: 'Sport', name: 'Baseball'},
  // {file: 'Thanks_Rainbow.jpg', category: 'Thanks', name: 'Rainbow'},
  {file: 'Thank-You-Event-INVITE.png', category: 'Thanks', name: 'Rainbow'},
  {file: 'Walk_Dog.jpg', category: 'Walk', name: 'Dog'},
  {file: 'Walk_Group.jpg', category: 'Walk', name: 'Group'},
  {file: 'Walk_Group-2.jpg', category: 'Walk', name: 'Group 2'},
  {file: 'Walk_Group-3.jpg', category: 'Walk', name: 'Group 3'},
  {file: 'Walk_Sneakers.jpg', category: 'Walk', name: 'Sneakers'},
  {file: 'Walk_Woman-with-Friends.jpg', category: 'Walk', name: 'Woman with Friends'},
  {file: 'Woman_3-Women-Laughing.jpg', category: 'Woman', name: '3 Women Laughing'},
  {file: 'Woman_Crossword-Puzzle.jpg', category: 'Woman', name: 'Crossword Puzzle'},
  {file: 'Woman_Girl-Telling-Secret.jpg', category: 'Woman', name: 'Girl Telling Secret'},
  {file: 'Woman_Grocery-Store.jpg', category: 'Woman', name: 'Grocery Store'},
  {file: 'Woman_Happy.jpg', category: 'Woman', name: 'Happy'},
  {file: 'Woman_Hiking.jpg', category: 'Woman', name: 'Hiking'},
  {file: 'Woman_Laptop-in-Kitchen.jpg', category: 'Woman', name: 'Laptop in Kitchen'},
  {file: 'Woman_Leaning-on-Wall.jpg', category: 'Woman', name: 'Leaning on Wall'},
  {file: 'Woman_Looking-in-Mirror.jpg', category: 'Woman', name: 'Looking in Mirror'},
  {file: 'Woman_Reading-Food-Labels.jpg', category: 'Woman', name: 'Reading Food Labels'},
  {file: 'Woman_Sick.jpg', category: 'Woman', name: 'Sick'},
  {file: 'Woman_Smiling.jpg', category: 'Woman', name: 'Smiling'},
  {file: 'Woman_Talking-to-Pharmacist.jpg', category: 'Woman', name: 'Talking to Pharmacist'}
];
export { HeaderImage, headerImages };
