import {Component, OnDestroy, ViewChild, OnInit, ComponentFactoryResolver} from '@angular/core';
import {EventInfo, eventInfoWithVariant} from '../models/event-info';
import * as moment from 'moment';
import {Four51Service} from '../four51.service';
import {OrderListItem} from '../models/order-list-item';
import {NgbModal, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Subscription} from 'rxjs';
import {NgbDateCustomParserFormatter} from '../dateformat';
import {AssetProofComponent} from './asset-proof.component';
import {ShipToComponent} from './ship-to.component';
import {HttpClient} from '@angular/common/http';
import {MailerMessage, MailerService} from '../mailer.service';
import {EmailInvitationService} from './assets/email-invitation.service';
import {Button, ModalComponent} from '../modal.component';
import {VoiceshotService} from './assets/voiceshot.service';
import {FacebookService} from './assets/facebook.service';
import {EventWaiverService} from './assets/event-waiver.service';
import {MembershipGiveawayEditComponent} from './membership-giveaway-edit.component';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {AddressService} from '../address.service';
import {InvitationService} from './assets/invitation.service';
import {environment} from '../../environments/environment';
import {PhysicianEmailAddrService} from '../physician-email-addr.service';
import has = Reflect.has;

@Component({
  selector: 'app-event-approval',
  templateUrl: './event-approval.component.html',
  styleUrls: ['./event-approval.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})

export class EventApprovalComponent implements OnInit, OnDestroy {

  private masterEventInfo: EventInfo;
  private masterLineItem;
  public selectedOrderId: string;
  private selectedOrder: any;
  private topic: any;
  private digitalLineItems: any;
  private primaryShipTo: any;
  private primaryShipToLineItems: any;
  private secondaryShipTo: any;
  private secondaryShipToLineItems: any;
  private selectedOrderLine: any;
  private orders: OrderListItem[] = [];
  private isOrderListWaiting = false;
  private page = 1;
  private pageSize = 10;
  private subscription: Subscription;

  private eventDate: NgbDate;

  private emailMss = true;
  private emailDr = true;
  private emailStaff: boolean;
  private emailPdm: boolean;
  private emailPtm: boolean;
  private emailPdmMgt = true;
  private emailOther = false;
  private otherEmails: string;

  private primaryItemsToAdd: any[] = [];
  private secondaryItemsToAdd: any[] = [];

  public submitted = false;
  public isWholePageWaiting = false;

  private membershipGiveawayProductId = 'A678D8E2-AD56-4B10-BB5B-08142796E227';
  private isMembershipGiveaway;
  private membershipGiveawayDeliverDate;
  private membershipGiveawayComments;

  private isApprovedOrder = false;

  constructor(private four51Service: Four51Service, private modalService: NgbModal,
              private route: ActivatedRoute, private http: HttpClient, private mailerService: MailerService,
              private voiceshotService: VoiceshotService, private emailInvitationService: EmailInvitationService,
              private facebookService: FacebookService, private eventWaiverService: EventWaiverService,
              private mailTemplateService: MailTemplateService, private addressService: AddressService,
              private invitationService: InvitationService, private physicianEmailAddrService: PhysicianEmailAddrService) {
    this.getOrders();
  }

  ngOnInit(): void {
    this.subscription = new Subscription();
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const orderId = params.get('orderid');

      if (orderId) {
        this.selectOrder(orderId);
      } else {
        this.selectedOrderId = null;
        this.selectedOrder = null;
      }
    });
    this.subscription.add(paramMapSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async getOrders() {
    this.isOrderListWaiting = true;
    const response: any = await this.four51Service.getOrders('AwaitingApproval').toPromise();
    if (response.Count > 0) {
      this.orders = response.List.map(result => {
        const orderListItem = new OrderListItem();
        orderListItem.DateCreated = moment(result.DateCreated);
        orderListItem.DateSubmittedForApproval = moment(result.DateSubmittedForApproval);
        orderListItem.ExternalID = result.ExternalID;
        orderListItem.FromUserFirstName = result.FromUserFirstName;
        orderListItem.FromUserLastName = result.FromUserLastName;
        orderListItem.ID = result.ID;
        orderListItem.StatusText = result.StatusText;
        return orderListItem;
      });
      this.getOrderDetails();
    }
    this.isOrderListWaiting = false;
  }

  getOrderDetails() {
    for (const orderListItem of this.orders) {
      this.four51Service.getOrderById(orderListItem.ID)
        .subscribe((response: any) => {
          const invitationLineItem = response.LineItems
            .filter(x => (x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH' ))[0];
          if (invitationLineItem) {
            const dataText = (invitationLineItem.Variant.Specs.data) ? invitationLineItem.Variant.Specs.data.Value : '{}';
            const data = JSON.parse(dataText);
            orderListItem.pdmEmail = (data.pdmEmail) || 'N/A';
            orderListItem.TopicTitle = invitationLineItem.Variant.Specs.title.Value;
            orderListItem.DoctorFullName = invitationLineItem.Variant.Specs.fullName.Value;
            if (invitationLineItem.Variant.Specs.event_date.Value.length > 0) {
              orderListItem.EventDate = moment(invitationLineItem.Variant.Specs.event_date.Value, 'YYYY-M-D');
            }
          } else {
            const membershipGiveawayLineItem = response.LineItems
              .find(x => (x.Product.InteropID === this.membershipGiveawayProductId));
            if (membershipGiveawayLineItem) {
              orderListItem.pdmEmail = 'N/A';
              orderListItem.TopicTitle = 'Membership Giveaway';
              orderListItem.DoctorFullName = membershipGiveawayLineItem.Variant.Specs.V03Full_Name.Value;
              if (membershipGiveawayLineItem.Variant.Specs.Date.Value.length > 0) {
                orderListItem.EventDate = moment(membershipGiveawayLineItem.Variant.Specs.Date.Value, 'MM/DD/YYYY');
              }
            } else {
              this.orders = this.orders.filter(x => x !== orderListItem);
            }
          }
      });
    }
  }

  declineOrder(order: OrderListItem) {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.title = 'Decline Order';
    modalRef.componentInstance.body = 'Are you sure you want to decline this request for ' + order.DoctorFullName + '?';
    const cancelButton: Button = {
      label: 'Cancel',
      action: '',
      isNeutral: true
    };
    const okButton: Button = {
      label: 'OK',
      action: '',
      isNeutral: false
    };
    modalRef.componentInstance.buttons = [cancelButton, okButton];
    modalRef.result
      .then(async result => {
        // ok pressed
        const response: any = await this.four51Service.declineOrder(order.ID).toPromise();
        if (response.Approvals) {
          for (const approval of response.Approvals) {
            if (approval.Status === 'Declined') {
              this.orders = this.orders.filter(x => x.ID !== order.ID);
              break;
            }
          }
        }
      })
      .catch(reason => {
        // cancelled
      });
  }

  async selectOrder(orderId: string) {
    this.selectedOrderId = orderId;
    const response = await this.four51Service.getOrderById(orderId).toPromise();
    this.selectedOrder = response;

    const membershipGiveawayLineItem = this.selectedOrder.LineItems.find(x => x.Product.InteropID === this.membershipGiveawayProductId);
    if (membershipGiveawayLineItem) {
      this.isMembershipGiveaway = true;
    } else {
      this.isMembershipGiveaway = false;
    }

    if (this.isMembershipGiveaway) {
      // console.log(this.selectedOrder);
      this.primaryShipToLineItems = this.selectedOrder.LineItems;
      this.membershipGiveawayDeliverDate = membershipGiveawayLineItem.Variant.Specs.Date10.Value;
      const dataString = membershipGiveawayLineItem.Variant.Specs.data.Value;
      const data = JSON.parse(dataString);
      if (data && data.comments) {
        this.membershipGiveawayComments = data.comments;
      }
      const address: any = await this.four51Service.getAddresses(membershipGiveawayLineItem.ShipAddressID).toPromise();
      this.primaryShipTo = address;
    } else {
      this.masterLineItem = this.selectedOrder.LineItems
        .find(x => (x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH'));
      if (this.masterLineItem.Variant.Specs) {
        this.masterEventInfo = eventInfoWithVariant(this.masterLineItem.Variant);
      } else {
        this.masterEventInfo = eventInfoWithVariant(this.masterLineItem);
        this.isApprovedOrder = true;
      }
      this.eventDate = this.masterEventInfo.sessions[0].eventDate;
      const topics: any = await this.http.get('./assets/json/topics.json').toPromise();
      this.topic = topics.find(x => x.id === this.masterEventInfo.topicId);
      this.groupLineItems();
      if (!this.primaryShipTo) {
        // set placeholder just in case items are added
        this.primaryShipTo = {AddressName: this.masterEventInfo.Address + ' ' + this.masterEventInfo.City};
      }

      if (this.masterEventInfo.staffEmail && this.masterEventInfo.staffEmail.length > 0) {
        this.emailStaff = true;
      } else {
        this.emailStaff = false;
      }

      if (this.masterEventInfo.pdmEmail && this.masterEventInfo.pdmEmail.length > 0) {
        if (this.masterEventInfo.practiceStatus === 'Transition') {
          this.emailPdm = false;
        } else {
          this.emailPdm = true;
        }
      } else {
        this.emailPdm = false;
      }

      if (this.masterEventInfo.ptmEmail && this.masterEventInfo.ptmEmail.length > 0) {
        if (this.masterEventInfo.practiceStatus === 'Post-Open') {
          this. emailPtm = false;
        } else {
          this. emailPtm = true;
        }
      } else {
        this. emailPtm = false;
      }
    }
  }

  async groupLineItems() {
    const groupedLineItems = this.selectedOrder.LineItems.reduce((rv, x) => {
      (rv[x['ShipAddressID']] = rv[x['ShipAddressID']] || []).push(x);
      return rv;
    }, {});
    for (const key of Object.keys(groupedLineItems)) {
      if (key === 'null') {
        this.digitalLineItems = groupedLineItems[key];
      } else {
        // const shipAddressID = groupedLineItems[key][0].ShipAddressID;
        const address: any = await this.four51Service.getAddresses(key).toPromise();
        if (address) {
          if (address.InteropID === this.masterEventInfo.shippingAddressID) {
            this.primaryShipTo = address;
            this.primaryShipToLineItems = groupedLineItems[key];
          } else if (address.InteropID === 'MDVIP-DownloadAddress') {
            this.digitalLineItems = groupedLineItems[key];
          } else if (this.masterEventInfo.secondShipToAddress) {
            if (address.InteropID === this.masterEventInfo.secondShippingAddressID) {
              this.secondaryShipTo = address;
              this.secondaryShipToLineItems = groupedLineItems[key];
            }
          }
        }
      }
    }
    this.updateItemsToAdd();
  }

  updateItemsToAdd() {
    this.primaryItemsToAdd = [];
    let hasInvitation = false;
    // let hasAttendanceCards = false;
    let hasHandout = false;
    if (this.primaryShipToLineItems) {
      hasInvitation = this.primaryShipToLineItems.find(x =>
        (x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH'));
      /*hasAttendanceCards = this.primaryShipToLineItems.find(x =>
        x.Product.InteropID === 'C64164FE-9DD1-49CC-8BA3-5246DD623BC6');*/
      if (this.topic && this.topic.handouts && this.topic.handouts.length > 0 ) {
        hasHandout = this.primaryShipToLineItems.find(x =>
          x.Product.InteropID === this.topic.handouts[0]);
      }
    }
    if (!hasInvitation) {
      this.primaryItemsToAdd.push('Invitation');
    }
    /*if (!hasAttendanceCards) {
      this.primaryItemsToAdd.push('Attendance Cards');
    }*/
    if (this.topic && this.topic.handouts && this.topic.handouts.length > 0 && !hasHandout) {
        this.primaryItemsToAdd.push('Handouts');
    }
    if (this.secondaryShipTo) {
      this.secondaryItemsToAdd = [];
      let hasInvitationSecondary = false;
      let hasHandoutSecondary = false;
      if (this.secondaryShipToLineItems) {
        hasInvitationSecondary = this.secondaryShipToLineItems.find(x =>
          (x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH'));
        if (this.topic && this.topic.handouts && this.topic.handouts.length > 0 ) {
          hasHandoutSecondary = this.secondaryShipToLineItems.find(x =>
            x.Product.InteropID === this.topic.handouts[0]);
        }
      }
      if (!hasInvitationSecondary) {
        this.secondaryItemsToAdd.push('Invitation');
      }
      if (this.topic && this.topic.handouts && this.topic.handouts.length > 0 && !hasHandoutSecondary) {
        this.secondaryItemsToAdd.push('Handouts');
      }
    }
  }

  async onItemAdd(e, item: string, shipTo: any) {
    if (shipTo === this.primaryShipTo && !shipTo.Zip) {
      // lazy load primary ship to
      this.primaryShipTo = await this.getOrCreatePrimaryAddress();
      shipTo = this.primaryShipTo;
      this.masterEventInfo.shippingAddressID = shipTo.InteropID;
      this.saveMasterEventInfo();
    }
    switch (item) {
      case 'Invitation': {
        const lineItem: any = {
          Product: {
            InteropID: this.masterLineItem.Product.InteropID
          },
          Quantity: 50,
          Variant: {InteropID: this.masterLineItem.Variant.InteropID},
          ShipAddressID: shipTo.ID
        };
        // this.selectedOrder.LineItems.push(lineItem);
        this.selectedOrder.LineItems.unshift(lineItem); // add physical shipping items to beginning
        break;
      }
      /*case 'Attendance Cards': {
        const lineItem: any = {
          Product: {
            InteropID: 'C64164FE-9DD1-49CC-8BA3-5246DD623BC6'
          },
          Quantity: 1,
          ShipAddressID: shipTo.ID
        };
        // this.selectedOrder.LineItems.push(lineItem);
        this.selectedOrder.LineItems.unshift(lineItem); // add physical shipping items to beginning
        break;
      }*/
      case 'Handouts': {
        if (this.topic && this.topic.handouts && this.topic.handouts.length > 0) {
          let quantity = this.topic.handout_quantities[0];
          if (quantity === 0 && this.topic.handout_quantities.length > 1) {
            quantity = this.topic.handout_quantities[1];
          }
          for (const handout of this.topic.handouts) {
            const lineItem: any = {
              Product: {
                InteropID: handout
              },
              Quantity: quantity,
              ShipAddressID: shipTo.ID
            };
            // this.selectedOrder.LineItems.push(lineItem);
            this.selectedOrder.LineItems.unshift(lineItem); // add physical shipping items to beginning
          }
        }
        break;
      }
    }
    const orderResponse = await this.four51Service.createOrUpdateOrder(this.selectedOrder).toPromise();
    this.selectedOrder = orderResponse.body;
    this.groupLineItems();
  }

  selectLineItem(lineItem: any) {
    // console.log(lineItem);
    this.selectedOrderLine = lineItem;
    if (this.isMembershipGiveaway) {
      const modalRef = this.modalService.open(MembershipGiveawayEditComponent,
        {size: 'xl', scrollable: true,  windowClass : 'modal-proof', backdrop: 'static'});
      const membershipGiveawayEditComponent: MembershipGiveawayEditComponent = modalRef.componentInstance;
      membershipGiveawayEditComponent.product = this.selectedOrderLine.Product;
      membershipGiveawayEditComponent.variant = this.selectedOrderLine.Variant;
      modalRef.result
        .then(result => {
          // done clicked
        })
        .catch(() => {
          // close box clicked
        });
    } else {
      const modalRef = this.modalService.open(AssetProofComponent, {size: 'xl', scrollable: true,  windowClass : 'modal-proof',
        backdrop: 'static'});
      modalRef.result
        .then(result => {
          // console.log('proof modal closed');
        })
        .catch(result => {
          // console.log('proof modal cancelled');
          if (this.selectedOrderLine.Product.InteropID === 'MDVIP-Invitation'
            || this.selectedOrderLine.Product.InteropID === 'MDVIP-InvitationYOCH') {
            // update all the invitations with this variant info and reset master
            const updatedEventInfo: EventInfo = eventInfoWithVariant(this.selectedOrderLine.Variant);
            if (JSON.stringify(updatedEventInfo) !== JSON.stringify(this.masterEventInfo)) {
              // invitation changed
              this.masterEventInfo = updatedEventInfo;
              // console.log('invitation changed');
              this.updateOtherInvitationLineItems(this.selectedOrderLine);
              this.masterEventInfo = eventInfoWithVariant(this.masterLineItem.Variant);
              const copyDataModalRef = this.modalService.open(ModalComponent);
              copyDataModalRef.componentInstance.title = 'Apply To Other Items?';
              copyDataModalRef.componentInstance.body = 'Do you want to apply these edits to the other items in the cart?';
              const noButton: Button = {
                label: 'No',
                action: '',
                isNeutral: true
              };
              const yesButton: Button = {
                label: 'Yes',
                action: '',
                isNeutral: false
              };
              copyDataModalRef.componentInstance.buttons = [noButton, yesButton];
              copyDataModalRef.result
                .then(async copyDataResult => {
                  // yes pressed
                  this.updateNonInvitationLineItems();
                })
                .catch(reason => {
                  // no pressed
                });
            }
          }
          if (this.selectedOrderLine.Product.InteropID === 'MDVIP-InvitationEmail') {
            // update the rsvp online for the invite and master
            const dataString = (this.selectedOrderLine.Variant.Specs.data) ? this.selectedOrderLine.Variant.Specs.data.Value : '{}';
            const data = JSON.parse(dataString);
            const rsvpOnline: boolean = data.rsvpOnline;
            if (rsvpOnline !== this.masterEventInfo.rsvpOnline) {
              this.masterEventInfo.rsvpOnline = rsvpOnline;
              this.saveMasterEventInfo();
            }
          }
        });
      modalRef.componentInstance.lineItem = this.selectedOrderLine;
    }
  }

  updateOtherInvitationLineItems(lineItem) {
    const otherInvitationLineItems = this.selectedOrder.LineItems
      .filter(x => (x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH') && x.ID !== lineItem.ID);
    for (const otherInvitationLineItem of otherInvitationLineItems) {
      otherInvitationLineItem.Variant = lineItem.Variant;
    }
  }

  async updateNonInvitationLineItems() {
    const nonInvitationLineItems = this.selectedOrder.LineItems
      .filter(x => !(x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH'));
    for (const nonInvitationLineItem of nonInvitationLineItems) {
      switch (nonInvitationLineItem.Product.InteropID) {
        case 'MDVIP-InvitationEmail': {
          const variant = await this.emailInvitationService.refresh(nonInvitationLineItem.Variant, this.masterEventInfo);
          nonInvitationLineItem.Variant = variant;
          break;
        }
        case 'MDVIP-VoiceShot':
        case 'MDVIP-VoiceShotYOCH': {
          const variant = await this.voiceshotService.refresh(nonInvitationLineItem.Variant, this.masterEventInfo);
          nonInvitationLineItem.Variant = variant;
          break;
        }
        case 'MDVIP-Facebook':
        case 'MDVIP-FacebookYOCH': {
          const variant = await this.facebookService.refresh(nonInvitationLineItem.Variant, this.masterEventInfo);
          nonInvitationLineItem.Variant = variant;
          break;
        }
        case 'MDVIP-EventWaiver': {
          const variant = await this.eventWaiverService.refresh(nonInvitationLineItem.Variant, this.masterEventInfo);
          nonInvitationLineItem.Variant = variant;
          break;
        }
      }
    }
  }

  async editAddress(address) {
    const modalRef = this.modalService.open(ShipToComponent, {size: 'xl', scrollable: true, backdrop: 'static'});
    modalRef.result
      .then(result => {
        Object.assign(address, result);
        address.IsCustEditable = true;
        address.IsShipping = true;
        address.AddressName = address.Street1 + ' ' + address.City;
        this.four51Service.createAddress(result).toPromise()
          .then(res => {
            // console.log(res);
          });
      })
      .catch(result => {
        // console.log('ship to modal cancelled');
      });
    const modal: ShipToComponent = modalRef.componentInstance;
    if (address === this.primaryShipTo && !address.Zip) {
      // lazy load primary ship to
      this.primaryShipTo = await this.getOrCreatePrimaryAddress();
      address = this.primaryShipTo;
      this.masterEventInfo.shippingAddressID = address.InteropID;
      this.saveMasterEventInfo();
    }
    modalRef.componentInstance.shipAddress = Object.assign({}, address);
  }

  async getOrCreatePrimaryAddress(): Promise<any> {
    const response: any = await this.four51Service.getAddresses('shipping').toPromise();
    const shippingAddresses = response.List;
    const newAddress = await this.addressService.findOrCreateShippingAddress(
      shippingAddresses,
      this.masterEventInfo.fullName,
      this.masterEventInfo.Address,
      this.masterEventInfo.Address2,
      this.masterEventInfo.City,
      this.masterEventInfo.State,
      this.masterEventInfo.Zip,
      'US',
      this.masterEventInfo.Phone,
      'Dr',
      this.masterEventInfo.LastName);
    return newAddress;
  }

  async removeLineItem(lineItem: any) {
    const response = await this.four51Service.deleteOrder(this.selectedOrderId, lineItem.ID).toPromise();
    this.selectedOrder.LineItems = this.selectedOrder.LineItems.filter(x => x.ID !== lineItem.ID);
    this.primaryShipToLineItems = this.primaryShipToLineItems.filter(x => x.ID !== lineItem.ID);
    if (this.secondaryShipTo) {
      this.secondaryShipToLineItems = this.secondaryShipToLineItems.filter(x => x.ID !== lineItem.ID);
      if (this.secondaryShipToLineItems.length === 0) {
        this.secondaryShipTo = null;
        this.masterEventInfo.secondShipToAddress = false;
        this.masterEventInfo.secondShippingAddressID = '';
        this.saveMasterEventInfo();
      }
    }
    this.updateItemsToAdd();
  }

  async updateQuantities() {
    for (const lineItem of this.selectedOrder.LineItems) {
      lineItem.LineTotal = lineItem.UnitPrice * lineItem.Quantity;
    }
    const response = await this.four51Service.createOrUpdateOrder(this.selectedOrder).toPromise();
  }

  async saveMasterEventInfo() {
    const dataString = (this.masterLineItem.Variant.Specs.data) ? this.masterLineItem.Variant.Specs.data.Value : '{}';
    const data = JSON.parse(dataString);
    data.shippingAddressID = this.masterEventInfo.shippingAddressID;
    data.secondShipToAddress = this.masterEventInfo.secondShipToAddress;
    data.secondShippingAddressID = this.masterEventInfo.secondShippingAddressID;
    if (data.rsvpOnline !== this.masterEventInfo.rsvpOnline) {
      this.saveEmailEventInfo();
    }
    data.rsvpOnline = this.masterEventInfo.rsvpOnline;
    data.roomCapacity = this.masterEventInfo.roomCapacity;
    this.masterLineItem.Variant.Specs.data.Value = JSON.stringify(data);
    const response = await this.four51Service.createVariant(this.masterLineItem.Variant).toPromise();
    this.updateOtherInvitationLineItems(this.masterLineItem);
  }

  async saveEmailEventInfo() {
    const emailLineItems = this.selectedOrder.LineItems.filter(x => x.Product.InteropID === 'MDVIP-InvitationEmail');
    if (emailLineItems.length > 0) {
      const emailLineItem = emailLineItems[0];
      const dataString = (emailLineItem.Variant.Specs.data) ? emailLineItem.Variant.Specs.data.Value : '{}';
      const data = JSON.parse(dataString);
      data.rsvpOnline = this.masterEventInfo.rsvpOnline;
      emailLineItem.Variant.Specs.data.Value = JSON.stringify(data);
      this.four51Service.createVariant(emailLineItem.Variant).toPromise().then(result => {
        // console.log(result);
      });
    }
  }

  async approveOrder() {
    this.isWholePageWaiting = true;
    let order: any;
    if (!this.isApprovedOrder) {
      // TODO do we need to save the order first? Variants are updated. Ship tos are updated.
      // rearrange items if necessary
      if (this.digitalLineItems &&
        ((this.primaryShipToLineItems && this.primaryShipToLineItems.length > 0) ||
        (this.secondaryShipToLineItems && this.secondaryShipToLineItems.length > 0))) {
        // this order has digital and physical items
        // remove the digital items so the physical items are first
        for (const digitalLineItem of this.digitalLineItems) {
          order = await this.four51Service.deleteOrder(this.selectedOrder.ID, digitalLineItem.ID).toPromise();
          delete digitalLineItem.ID;
        }
        // now add the digital items back
        order.LineItems = [...this.digitalLineItems];
        const response = await this.four51Service.createOrUpdateOrder(order).toPromise();
        this.selectedOrder = response.body;
      }
      order = await this.four51Service.approveOrder(this.selectedOrder.ID).toPromise();
    } else {
      order = await this.four51Service.getOrderById(this.selectedOrderId).toPromise();
    }
    this.sendEmails(order);
    await this.createCampaign(order);
  }

  async createCampaign(order: any) {
    // PatientType valid values are Member, Prospect, Past Patient or Both
    let PatientType = 'Member'; // If Invite Recent Prospects = No && Invite Former Patients = No, Type = Member
    if (this.masterEventInfo.eligibleProspects === 'yes') {
      if (this.masterEventInfo.pastPatients === 'yes') {
        PatientType = 'Both'; // If Invite Recent Prospects = Yes && Invite Former Patients = Yes, Type = Both
      } else {
        PatientType = 'Prospect'; // If Invite Recent Prospects = Yes && Invite Former Patients = No, Type = Prospect
      }
    } else {
      if (this.masterEventInfo.pastPatients === 'yes') {
        PatientType = 'Past Patient'; // If Invite Recent Prospects = No && Invite Former Patients = Yes, Type = Past Patient
      }
    }
    const eventTimeZone = this.masterEventInfo.sessions[0].eventStartTime.timeZone;
    const eventDateMoment = moment(this.ngbDateToString(this.masterEventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    const EventBeginTime = eventDateMoment.format('MM/DD/YYYY') + ' ' +
      this.masterEventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('hh:mm A');
    let EventEndTime = '';
    if (this.masterEventInfo.sessions[0].includeEventEndTime) {
      EventEndTime = eventDateMoment.format('MM/DD/YYYY') + ' ' +
        this.masterEventInfo.sessions[0].eventEndTime.moment.tz(eventTimeZone).format('hh:mm A');
    }
    const CampaignName = this.masterEventInfo.fullName + ' - ' + this.masterEventInfo.title.substring(0, 25) + ' - ' + eventDateMoment.format('MM/DD/YYYY');
    // const CampaignName = this.masterEventInfo.fullName + ' - ' + this.masterEventInfo.title + ' - ' + eventDateMoment.format('MM/DD/YYYY');
    const emailLineItem = order.LineItems
          .find(x => x.Product.InteropID === 'MDVIP-InvitationEmail');
    const EmailHTML = await this.emailInvitationService.getHtml(emailLineItem);
    let VenueAddress = this.masterEventInfo.venue_address_1;
    if (this.masterEventInfo.venue_address_2) {
      VenueAddress += ', ' + this.masterEventInfo.venue_address_2;
    }
    const data = {
      PatientType,
      AccountId: this.masterEventInfo.physicianId,
      EventBeginTime,
      EventEndTime,
      CampaignName,
      EmailHTML,
      VenueName: this.masterEventInfo.venue_name,
      VenueAddress,
      VenueCity: this.masterEventInfo.venue_city,
      VenueState: this.masterEventInfo.venue_state,
      VenueZipCode: this.masterEventInfo.venue_zip,
      VenueEventPhone: this.masterEventInfo.rsvp_phone.replace('.', ''),
      VenueRoomCapacity: +this.masterEventInfo.roomCapacity || 0
    };
    console.log(data)
    const createCampaignResponse = await this.four51Service.createCampaign(data).toPromise();
    console.log(createCampaignResponse);
  }

  async sendEmails(order: any) {
    try {
      if (this.isMembershipGiveaway) {
        /*
         * MG Approved Doctor Email
         */
        const membershipGiveawayLineItem = this.selectedOrder.LineItems.find(x => x.Product.InteropID === this.membershipGiveawayProductId);
        const fullName = membershipGiveawayLineItem.Variant.Specs.V03Full_Name.Value;
        const dataString = membershipGiveawayLineItem.Variant.Specs.data.Value;
        const data = JSON.parse(dataString);
        const mgApprovedDoctorEmail: MailerMessage = new MailerMessage();
        mgApprovedDoctorEmail.Subject = 'Your Recent Order: ' + fullName;
        mgApprovedDoctorEmail.Bcc = environment.emailBcc;
        if (!environment.production || environment.isTest) {
          mgApprovedDoctorEmail.To = environment.emailTo;
          mgApprovedDoctorEmail.Subject = 'TESTING: ' + mgApprovedDoctorEmail.Subject;
        } else {
          this.physicianEmailAddrService.setAddresses(mgApprovedDoctorEmail, data.email, data.staffEmail, data.ptmEmail, data.pdmEmail,
            data.practiceStatus, data.relationshipStatus, data.physicianStatusType );
        }
        mgApprovedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
        mgApprovedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
        const mgApprovedDoctorValues: MailTemplateValue[] = [
          {name: 'order_number', value: this.selectedOrder.ExternalID}
        ];
        mgApprovedDoctorEmail.Html = await this.mailTemplateService.replaceValues(
          './assets/html/mgApprovedDoctor.html', mgApprovedDoctorValues);
        const attachments: any[] = [];
        const scholarshipFormData = await this.getBase64Data('./assets/pdf/mdvip_scholarshipform_022814.pdf');
        const attachment = {
          base64Data: scholarshipFormData,
          contentType: 'application/pdf',
          filename: 'mdvip_scholarshipform_022814.pdf'
        };
        attachments.push(attachment);
        this.mailerService.sendMessageWithLogoAndAttachments(mgApprovedDoctorEmail, attachments);

        /* const res = await fetch('./assets/pdf/mdvip_scholarshipform_022814.pdf');
        const blob = await res.blob();
        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
          let base64Data = e.target.result;
          console.log(base64Data);
          base64Data = base64Data.replace('data:application/pdf;base64,', '');
          const attachment = {
            base64Data: base64Data,
            contentType: 'application/pdf',
            filename: 'mdvip_scholarshipform_022814.pdf'
          };
          attachments.push(attachment);
          this.mailerService.sendMessageWithLogoAndAttachments(mgApprovedDoctorEmail, attachments);
        };
        fileReader.readAsDataURL(blob);*/
      } else {
        /*
         * Event Approved Doctor Email
         */
        const eventApprovedDoctorEmail: MailerMessage = new MailerMessage();
        const eventDateMoment = moment(this.ngbDateToString(this.masterEventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
        eventApprovedDoctorEmail.Subject = this.masterEventInfo.fullName + ' Event Order ' + this.selectedOrder.ExternalID +
          ' has been approved';
        eventApprovedDoctorEmail.Bcc = environment.emailBcc;
        if (!environment.production || environment.isTest) {
          eventApprovedDoctorEmail.To = environment.emailTo;
          eventApprovedDoctorEmail.Subject = 'TESTING: ' + eventApprovedDoctorEmail.Subject;
        } else {
          this.physicianEmailAddrService.setAddresses(
            eventApprovedDoctorEmail,
            (this.emailDr) ? this.masterEventInfo.email : null,
            (this.emailStaff) ? this.masterEventInfo.staffEmail : null,
            (this.emailPtm) ? this.masterEventInfo.ptmEmail : null,
            (this.emailPdm) ? this.masterEventInfo.pdmEmail : null,
            this.masterEventInfo.practiceStatus,
            this.masterEventInfo.relationshipStatus,
            this.masterEventInfo.physicianStatusType
          );
        }
        if (this.emailOther && this.otherEmails.trim().length > 0) {
          const otherEmails = this.otherEmails.split(';');
          for (const otherEmail of otherEmails) {
            eventApprovedDoctorEmail.Bcc.push(otherEmail);
          }
        }
        if (eventApprovedDoctorEmail.To.length === 0) {
          eventApprovedDoctorEmail.To.push('Marketing Support Site <MarketingSupportSite@mdvip.com>');
        }
        eventApprovedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
        eventApprovedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
        let powerPointRowDisplay = 'none';
        let powerPointUrl = '';
        if (this.topic) {
          if (this.topic.powerpointfile && this.topic.powerpointfile.length > 0) {
            powerPointRowDisplay = 'table-row';
            if (this.topic.powerpointfile.length > 1 ) {
              powerPointUrl = 'these PowerPoint Presentation links ';
              for (let i = 0; i < this.topic.powerpointfile.length; i++) {
                const file = window.location.origin + '/assets/pptx/' + this.topic.powerpointfile[i] + '.pptx';
                powerPointUrl += '<a href="' + file + '"><span style="color:#DE6400;text-decoration:none">File ' + (i + 1) + '</span></a> ';
              }
            } else {
              const file = window.location.origin + '/assets/pptx/' + this.topic.powerpointfile[0] + '.pptx';
              powerPointUrl = 'this <a href="' + file +
                '"><span style="color:#DE6400;text-decoration:none">PowerPoint Presentation link</span></a>';
            }
          }
        }
        // const historyUrl = window.location.origin + '/history';
        const historyUrl = environment.mdvipConnectUrl;
        let proofUrl: string;
        if (this.isApprovedOrder) {
          proofUrl = this.masterLineItem.ProofUrl.replace('/PDF/', '/PDFO/');
        } else {
          proofUrl = this.masterLineItem.Variant.ProofUrl.replace('/PDF/', '/PDFO/');
        }
        const eventApprovedDoctorValues: MailTemplateValue[] = [
          {name: 'order_number', value: this.selectedOrder.ExternalID},
          {name: 'title', value: this.masterEventInfo.title},
          {name: 'event_date', value: eventDateMoment.format('dddd, MMMM D')},
          {name: 'invitation_url', value: proofUrl},
          {name: 'powerpoint_url', value: powerPointUrl},
          {name: 'powerpoint_row_display', value: powerPointRowDisplay},
          {name: 'history_url', value: historyUrl}
        ];
        /*eventApprovedDoctorEmail.Html = await this.mailTemplateService
          .replaceValues('./assets/html/eventApprovedDoctor.html', eventApprovedDoctorValues);*/

        // attachments Voice Shot - PDF Only, Facebook - PDF Only, MDVIP_TipSheet, Event Waiver - PDF Only
        const voiceShotLineItem = order.LineItems
          .find(x => (x.Product.InteropID === 'MDVIP-VoiceShot' || x.Product.InteropID === 'MDVIP-VoiceShotYOCH'));
        const voiceShotURL = environment.apiBaseUrl +
          (new URL(voiceShotLineItem.ProofUrl.replace('/PDF/', '/PDFO/')))
            .pathname.substring(1);
        const voiceShotName = voiceShotLineItem.Product.Name  + '.pdf';

        const facebookLineItem = order.LineItems
          .find(x => (x.Product.InteropID === 'MDVIP-Facebook' || x.Product.InteropID === 'MDVIP-FacebookYOCH'));
        const facebookURL = environment.apiBaseUrl +
          (new URL(facebookLineItem.ProofUrl.replace('/PDF/', '/PDFO/')))
            .pathname.substring(1);
        const facebookName = facebookLineItem.Product.Name + '.pdf';

        const eventWaiverLineItem = order.LineItems
          .find(x => (x.Product.InteropID === 'MDVIP-EventWaiver'));
        const eventWaiverURL = environment.apiBaseUrl +
          (new URL(eventWaiverLineItem.ProofUrl.replace('/PDF/', '/PDFO/')))
            .pathname.substring(1);
        const eventWaiverName = eventWaiverLineItem.Product.Name + '.pdf';

        const tipSheetURL = './assets/pdf/MDVIP_TipSheet.pdf';
        const tipSheetName = 'MDVIP_TipSheet.pdf';
        /*const tipSheetAbsoluteURL = window.location.protocol + '//' + window.location.hostname + '/assets/pdf/MDVIP_TipSheet.pdf';
        // const tipSheetAbsoluteURL = 'https://mdvipsf.seprint.com/assets/pdf/MDVIP_TipSheet.pdf';

        eventApprovedDoctorValues.push({name: 'voiceshot_url', value: voiceShotURL});
        eventApprovedDoctorValues.push({name: 'facebook_url', value: facebookURL});
        eventApprovedDoctorValues.push({name: 'tipsheet_url', value: tipSheetAbsoluteURL});
        eventApprovedDoctorValues.push({name: 'eventwaiver_url', value: eventWaiverURL});*/
        eventApprovedDoctorEmail.Html = await this.mailTemplateService
          .replaceValues('./assets/html/eventApprovedDoctor.html', eventApprovedDoctorValues);

        let voiceShotData;
        let facebookData;
        let eventWaiverData;
        let tipSheetData;

        await Promise.all([
          this.getBase64Data(voiceShotURL).then(data => { voiceShotData = data; }),
          this.getBase64Data(facebookURL).then(data => { facebookData = data; }),
          this.getBase64Data(eventWaiverURL).then(data => { eventWaiverData = data; }),
          this.getBase64Data(tipSheetURL).then(data => { tipSheetData = data; })
        ]);

        const doctorAttachments = [
          {
            base64Data: voiceShotData,
            contentType: 'application/pdf',
            filename: voiceShotName
          },
          {
            base64Data: facebookData,
            contentType: 'application/pdf',
            filename: facebookName
          },
          {
            base64Data: eventWaiverData,
            contentType: 'application/pdf',
            filename: eventWaiverName
          },
          {
            base64Data: tipSheetData,
            contentType: 'application/pdf',
            filename: tipSheetName
          },
        ];
        // console.log(doctorAttachments);

        this.mailerService.sendMessageWithLogoAndAttachments(eventApprovedDoctorEmail, doctorAttachments);
        // this.mailerService.sendMessageWithLogo(eventApprovedDoctorEmail);

        /*
         * Event Approved Internal Email
         */
        const eventApprovedInternalEmail: MailerMessage = new MailerMessage();
        eventApprovedInternalEmail.Subject = this.masterEventInfo.fullName + ' / Marketing Support HTML Email File';
        eventApprovedInternalEmail.Bcc = environment.emailBcc;
        if (!environment.production || environment.isTest) {
          eventApprovedInternalEmail.To = environment.emailTo;
          eventApprovedInternalEmail.Subject = 'TESTING: ' + eventApprovedInternalEmail.Subject;
        } else {
          eventApprovedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
        }
        eventApprovedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
        eventApprovedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
        let saAttention = '';
        let saAddress = '';
        let saCity = '';
        let saState = '';
        let saZip = '';
        if (this.masterEventInfo.secondShipToAddress && this.secondaryShipTo) {
          saAttention = this.secondaryShipTo.FirstName + ' ' + this.secondaryShipTo.LastName;
          saAddress = this.secondaryShipTo.Street1;
          if (this.secondaryShipTo.Street2 && this.secondaryShipTo.Street2.length > 0) {
            saAddress += '<br />' + this.secondaryShipTo.Street2;
          }
          saCity = this.secondaryShipTo.City;
          saState = this.secondaryShipTo.State;
          saZip = this.secondaryShipTo.Zip;
        }
        const eventApprovedInternalValues: MailTemplateValue[] = [
          {name: 'date_approved', value:  moment().format('MM/DD/YYYY h:mm A')},
          {name: 'title', value: this.masterEventInfo.title },
          {name: 'event_date', value:  eventDateMoment.format('dddd, MMMM D') },
          {name: 'fullname', value: this.masterEventInfo.fullName },
          {name: 'pdm_email', value: (this.masterEventInfo.pdmEmail) ? this.masterEventInfo.pdmEmail : '' },
          {name: 'ptm_email', value: (this.masterEventInfo.ptmEmail) ? this.masterEventInfo.ptmEmail : '' },
          {name: 'room_capacity', value: (this.masterEventInfo.roomCapacity) ? this.masterEventInfo.roomCapacity : '' },
          {name: 'rsvp_online', value: (this.masterEventInfo.rsvpOnline) ? 'Yes' : 'No' },
          {name: 'channel', value: this.masterEventInfo.channel },
          {name: 'practice_status', value: this.masterEventInfo.practiceStatus },
          {name: 'relationship_status', value: this.masterEventInfo.relationshipStatus },
          {name: 'physician_status_type', value: this.masterEventInfo.physicianStatusType },
          {name: 'eligible_prospects', value: this.yesNoOrNull(this.masterEventInfo.eligibleProspects) },
          {name: 'past_patients', value: this.yesNoOrNull(this.masterEventInfo.pastPatients) },
          {name: 'ship_to_second_address', value: (this.masterEventInfo.secondShipToAddress) ? 'Yes' : 'No' },
          {name: 'display_second_address', value: (this.masterEventInfo.secondShipToAddress) ? 'table-row' : 'none' },
          {name: 'sa_attention', value: saAttention },
          {name: 'sa_address', value: saAddress },
          {name: 'sa_city', value: saCity },
          {name: 'sa_state', value: saState },
          {name: 'sa_zip', value: saZip },
          {name: 'comment', value: (this.masterEventInfo.comments) ? this.masterEventInfo.comments : '' },
          {name: 'comment_name', value: (this.masterEventInfo.commentsName) ? this.masterEventInfo.commentsName : '' },
          {name: 'comment_phone', value: (this.masterEventInfo.commentsPhone) ? this.masterEventInfo.commentsName : '' },
        ];

        eventApprovedInternalEmail.Html = await this.mailTemplateService
          .replaceValues('./assets/html/eventApprovedInternal.html', eventApprovedInternalValues);


        // get HTML for attachment
        const attachments: any[] = [];
        const emailLineItems = this.selectedOrder.LineItems.filter(x => x.Product.InteropID === 'MDVIP-InvitationEmail');
        if (emailLineItems.length > 0) {
          let html;
          const emailLineItem = emailLineItems[0];
          if (this.isApprovedOrder) {
            html = await this.emailInvitationService.getHtml(emailLineItem);
          } else {
            html = await this.emailInvitationService.getHtml(emailLineItem.Variant);
          }
          const base64Data = btoa(unescape(encodeURIComponent(html)));
          // const base64Data = btoa(html.replace(/[\u00A0-\u2666]/g, function(c) {
          //  return '&#' + c.charCodeAt(0) + ';';
          // }));
          // const base64Data = btoa('<html><head><title>Hello World</title></head><body><div>Hello World</div></body></html>');
          const attachment = {
            base64Data: base64Data,
            contentType: 'text/html',
            filename: 'email-invitation.html'
          };
          attachments.push(attachment);
        }
        this.mailerService.sendRawMessage(eventApprovedInternalEmail, attachments);
      } // if (this.isMembershipGiveaway)
      this.isWholePageWaiting = false;
      this.submitted = true;
    } catch (error) {
      // console.log(error);
      const errorEmail: MailerMessage = new MailerMessage();
      errorEmail.To = ['tom@artoftechnology.com'];
      errorEmail.From = 'MarketingSupportSite@mdvip.com';
      errorEmail.Subject = 'Event Approval Email Error';
      errorEmail.Body = error.message;
      errorEmail.Html = '<div>' + error.message + '</div>';
      this.mailerService.sendMessage(errorEmail);
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.title = 'Error Sending Email';
      modalRef.componentInstance.body = 'The order was approved, but there was an error sending the emails. Please try again.';
      const cancelButton: Button = {
        label: 'Cancel',
        action: '',
        isNeutral: true
      };
      const okButton: Button = {
        label: 'Send Emails',
        action: '',
        isNeutral: false
      };
      modalRef.componentInstance.buttons = [cancelButton, okButton];
      modalRef.result
        .then(async result => {
          // ok pressed
          this.sendEmails(order);
        })
        .catch(reason => {
          // cancelled
        });
    } finally {
      this.isWholePageWaiting = false;
    }
  }

  yesNoOrNull(str: string): string {
    // console.log(str);
    if (str) {
      switch (str.toLowerCase()) {
        case 'yes': {
          return 'Yes';
        }
        case 'no': {
          return 'No';
        }
      }
    }
    return 'NULL';
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }

  async getBase64Data(url) {
    const blob = await this.http.get(url, {responseType: 'blob'}).toPromise();
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fileReader.onload = () => {
        let base64Data = fileReader.result.toString();
        base64Data = base64Data.replace('data:application/pdf;base64,', '');
        resolve(base64Data);
      };
      fileReader.readAsDataURL(blob);
    });
  }
}
